<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <div>
        <van-sticky>
          <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
        </van-sticky>
      </div>

      <van-grid clickable :column-num="2">
        <van-grid-item
          icon="http://kmfile.bjznpz.com/wwwroot/zn_files/210326/fe7adea1aeff434b9dc6e804b42caa86.png"
          text="分享中酿心声"
          @click="selectAddType(0)"
        />
        <van-grid-item
          icon="http://kmfile.bjznpz.com/wwwroot/zn_files/210519/b611f91e12a34a8887abe27df78aef26.png"
          text="分享经验萃取"
          @click="selectAddType(1)"
        />
        <van-grid-item
          icon="/image/good.png"
          text="分享学习成长"
          @click="selectAddType(2)"
        />
        <van-grid-item
          icon="http://kmfile.bjznpz.com/wwwroot/zn_files/210326/ec5c8bf6e85c4c4b8a5edf6883a2d884.png"
          text="授课意向提报"
          @click="tocourseadd"
        />

        <van-grid-item
          icon="http://kmfile.bjznpz.com/wwwroot/zn_files/220407/33ef0fec623446ce838b15c125196956.png"
          text="工作总结"
          @click="toaddWorkLog"
        />
        <van-grid-item
          icon="/image/Signin.png"
          text="工作打卡"
          @click="toworksignin"
        />
      </van-grid>

      <!-- <div style="margin: 15px 10px 10px 10px">
        <h2>请选择类型</h2>
        <div class="btn-box">
          <div><p>分享信息</p></div>
          <van-button
            type="info"
            size="large"
            round
            block
            @click="selectAddType(0)"
            >分享中酿心声</van-button
          >
        </div>
        <div class="btn-box">
          <div><p>分享S7进阶攻略</p></div>
          <van-button
            type="info"
            size="large"
            round
            block
            @click="selectAddType(1)"
            >S7进阶攻略</van-button
          >
        </div>
      

        <div class="btn-box">
          <div><p>授课意向提报</p></div>
          <van-button
            type="info"
            size="large"
            round
            block
            @click="tocourseadd()"
            >我要授课</van-button
          >
        </div>

        <div class="btn-box">
          <div><p>工作总结</p></div>
          <van-button
            type="info"
            size="large"
            round
            block
            @click="toaddWorkLog()"
            >写总结</van-button
          >
        </div>
         <div class="btn-box">
          <div><p>工作打卡</p></div>
          <van-button
            type="info"
            size="large"
            round
            block
            @click="toworksignin()"
            >工作打卡</van-button
          >
        </div>
          <div class="btn-box">
          <div><p>分享推荐身边优秀</p></div>
          <van-button
            type="info"
            size="large"
            round
            block
            @click="selectAddType(2)"
            >推荐身边优秀</van-button
          >
        </div>
      </div> -->

      <van-action-sheet
        v-model="isShowSelectTemplate"
        :actions="workLogTemplates"
        @select="onSelectTemplate"
        cancel-text="取消"
        close-on-click-action
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "share",
  data() {
    return {
  

      title: "添加分享",

      isShowSelectTemplate: false,
      workLogTemplates: [],
    };
  },
  components: {},
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.loading = true;
        this.initData();
      }
    },
  },
  mounted() {},
  computed: {},
  methods: {
    ...mapGetters(["getUserWorkLogTemplates"]),

    async initData() {
      this.title = "添加分享";
      this.getUserWorkLogTemplates().then((d) => {
        this.workLogTemplates = d.map((x) => ({ id: x.id, name: x.title }));
      });
      this.loading = false;
      return;
    },

    selectAddType(systemType) {
      this.$router.push({
        path: "/bbs/add",
        query: { systemType: systemType },
      });
    },

    tocourseadd() {
      this.$router.push({ path: "/course/add" });
    },
    toworksignin() {
      this.$router.push({ path: "/worksignin" });
    },
    toaddWorkLog() {
      if (this.workLogTemplates.length <= 1) {
        this.$router.push({
          path: "/worklog/add",
          query: {
            templateId:
              this.workLogTemplates.length == 1
                ? this.workLogTemplates[0].id
                : null,
          },
        });
      } else {
        this.isShowSelectTemplate = true;
      }
    },

    onSelectTemplate(item) {
      this.isShowSelectTemplate = false;
      this.$router.push({
        path: "/worklog/add",
        query: { templateId: item.id },
      });
    },
  },
};
</script>

<style>
/* .ql-fullscreen {
  width: 20px !important;
  background: url("/image/fullscreen.svg") no-repeat center !important;
} */
</style>
<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.van-checkbox {
    margin-top: 5px;
  }
  /deep/ .btn-box {
    margin-top: 25px;
  }
}
</style>